// Set file variable
$filename: 'scss/custom/_custom-alt.scss';

/*==============================================================================
  @Action - #{$filename}
==============================================================================*/

@include media-min('medium') {
  .action-menu .button {
    background: $accent;
    background: var(--accent, $accent);

    &:hover,
    &:focus {
      background: $accent-hover;
      background: var(--accent-hover, $accent-hover);
    }
  }
}
