// Set file variable
$filename: 'scss/custom/_custom.scss';

/*==============================================================================
  @Structure - #{$filename}
==============================================================================*/

/**
 * Front-page Content
 */

.front-page-content {
  display: flex;

  .content-image {
    flex: 1 0 210px;
    margin-right: 2em;

    img {
      border: 6px solid $white;
      border-radius: 50%;
    }
  }
}
