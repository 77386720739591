// Set file variable
$filename: 'scss/custom/_header-logo.scss';

/*==============================================================================
  @Logo - #{$filename}
==============================================================================*/

.logo {
  display: inline-block;
  margin: 0 0 1em 0;
  text-align: center;
  color: $color;
  border: none;

  > span {
    display: block;
  }

  &:visited {
    color: $color;
  }

  &:hover {
    color: $color;
    text-decoration: none;
  }
}

@include media-min('medium') {
  .logo {
    text-align: left;
    margin: 0 2em 0 0;
  }

  .logo-region {
    display: flex;
    align-items: center;
  }
}

/**
 * Logo Parts
 */

.logo .name {
  font-size: px-to-em(26px);
  font-weight: font-weight('regular');
  line-height: 1.3em;
}

.logo .member {
  font-style: italic;
  color: $color-light;
  margin-top: 4px;
}

.logo .region {
  display: block;
  font-size: px-to-em(18px);
  font-weight: bold;
}

.logo .sep {
  display: block;
  width: 40px;
  height: 1px;
  margin: 0.5em auto;
  background: rgba($black, 0.1);
}

@include media-min('medium') {
  .logo .region {
    display: inline-block;
  }

  .logo .sep {
    display: inline-block;
    width: 1px;
    height: 40px;
    margin: 0 1em;
    transform: rotate(15deg);
  }
}

/**
 * Vision Source Branding
 */

.logo .vs-logo,
.logo .vs-logo-sec {
  display: inline-block;
  vertical-align: top;
  @include text-hide();
}

.logo .vs-logo {
  width: 152px;
  height: 29px;
  margin: -10px 5px;
  background: url('/fs-shared/dist/img/vs-logo-gold.svg') no-repeat center center;
  background-size: 152px 29px;
}

.logo .vs-logo-sec {
  flex: 1 0 auto;
  width: 228px;
  height: 60px;
  margin-top: -10px;
  background: url('/fs-shared/dist/img/vs-logo-sec-dark.svg') no-repeat center center;
  background-size: 228px 60px;
}

@include media-min('medium') {
  .logo .vs-logo-sec {
    margin-top: -15px;
  }
}

/**
 * Custom Logo
 */

.logo_custom {
  img {
    margin: 0;
  }
}
