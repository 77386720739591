// Set file variable
$filename: 'scss/custom/_footer.scss';

/*==============================================================================
  @Footer - #{$filename}
==============================================================================*/

.footer {
  padding: 1em 0 0;
  background: $bg-footer;
  background: var(--bg-footer, $bg-footer);
  border-top: 1px solid $border-color;
}

@include media-min('medium') {
  .footer {
    padding: 2em 0 0;
  }
}

.footer {
  h3 {
    font-size: px-to-em(22px);
    font-weight: font-weight('semi-bold');
  }

  a {
    color: $color;

    &:hover,
    &:focus {
      color: $accent;
      color: var(--accent, $accent);
    }
  }

  .button {
    @include add-icon-button('left');
  }
}

/**
 * Footer Widget
 */

.footer .widget {
  padding: 1em 2em;
  margin: 0;

  h3 {
    margin: .5rem 0 1rem;
  }
}

/**
 * Footer Content
 */

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: 0.5em 0 1em;
}

.footer-content--item {
  flex: 1 0 100%;
  display: flex;
  align-items: stretch;
  margin: 0;

  .widget {
    flex: 1 1 auto;
  }
}

@include media-min('medium') {
  .footer-content {
    align-items: stretch;
    flex-direction: row;
    margin: 0 -1em 2em;
  }
  .footer-content--item {
    margin: 0 1em;
  }

  .footer-content--item-contact-us {
    flex: 0 1 50%;
  }

  .footer-content--item-locations {
    flex: 1 1 70%;
  }

  .footer-content--item-quicklinks {
    flex: 1 1 30%;
  }
}

/**
 * Footer Lists
 */

.footer-list {
  list-style: none;
  margin: 1em 0;

  li {
    margin: 0;
    padding: 1px 0;
  }

  li + li {
    border-top: 1px solid rgba($black, 0.1);
  }

  li a {
    display: block;
    padding: 0.5em 0;

    &:hover {
      color: $accent;
      color: var(--accent, $accent);
      text-decoration: none;
    }
  }

  .title,
  .timestamp {
    display: block;
  }

  .timestamp {
    color: $color-light;
  }
}

.footer-list-hours {
  li {
    padding: 0.5em 0;
  }
}

/**
 * Footer Social Links
 */

.footer-social-links {
  justify-content: center;
}

@include media-min('medium') {
  .footer-social-links {
    justify-content: flex-start;
  }
}

/**
 * Logo
 */

.footer-logo {
  margin: 2em 0;

  .vs-logo-sec {
    display: block;
    width: 180px;
    height: 47px;
    margin: 0 auto;
    background: url('/fs-shared/dist/img/vs-logo-sec-light.svg') no-repeat center center;
    background-size: 180px 47px;
    border: none;
    @include text-hide();
  }
}

/**
 * Footer Copyright
 */

.footer-copyright {
  padding: 1em 0;
  background: $color;
  background: var(--bg-body, $color);
  text-align: center;
  color: $white;

  h3 {
    color: $white;
  }

  .sep {
    margin: 0 0.5em;
    color: $color-light;
  }

  a {
    color: $white;

    &:visited {
      color: $white;
    }

    &:hover,
    &:focus {
      color: $accent;
      color: var(--accent, $accent);
    }
  }

  .button {
    background: rgba($white, 0.1);

    &:visited {
      color: $white;
    }

    &:hover,
    &:focus {
      color: $white;
      background: rgba($white, 0.2);
    }
  }
}

@include media-min('medium') {
  .footer-copyright {
    text-align: left;

    .footer-logo {
      float: right;
      margin: 0;
    }
  }
}
