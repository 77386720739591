// Set file variable
$filename: 'scss/custom/_buttons.scss';

/*==============================================================================
  @Buttons - #{$filename}
==============================================================================*/

.button {
  @include make-button((
    'border-radius' : 50px,
  ));
  @include add-button-color((
    'background-deg' : 2%,
  ));

  font-family: $font-family-heading;
  white-space: normal;

  &.block {
    display: block;
  }
}

.button.default {
  @include add-button-color((
    'background' : rgba($black, 0.05),
    'hover' : (
      'background' : rgba($black, 0.1)
    )
  ));
}

.button.primary {
  background: $color-button;

  &:hover {
    background: $color-button-hover;
  }

  &:active {
    background: $color-button-hover;
  }

  @include add-button-color((
    'color' : $white,
    'background' : var(--color-button, var(--accent, $color-button)),

    'hover' : (
      'background' : var(--color-button-hover, var(--accent-hover, $color-button-hover)),
    ),

    'active' : (
      'background' : var(--color-button-hover, var(--accent-hover, $color-button-hover)),
    ),
  ));
}

.button.outline {
  &:hover {
    background: $color-button;
  }

  &:active {
    background: $color-button;
  }

  @include add-button-color((
    'background' : transparent,
    'border' : 1px solid rgba($black, 0.1),

    'hover' : (
      'background' : transparent,
      'border-color' : var(--color-button, var(--accent, $color-button)),
    ),

    'active' : (
      'background' : transparent,
      'border-color' : var(--color-button, var(--accent, $color-button)),
    ),
  ));
}
