// Set file variable
$filename: 'scss/custom/_featured-blog.scss';

/*==============================================================================
  @Featured Blog - #{$filename}
==============================================================================*/

.featured-blog {
  padding: 1em 0;
  text-align: center;

  ul {
    list-style: none;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  ul li {
    margin: 1em 0;
  }

  img {
    display: inline-block;
    margin: 0 auto;
  }
}

@include media-min('medium') {
  .featured-blog {
    padding: 2em 0;

    ul {
      margin-top: 2em;
      margin-bottom: 2em;
    }

    ul li {
      margin: 0;
    }
  }
}

/**
 * Styles
 */

.featured-blog {
  background: $bg-main;
  background: var(--bg-main, $bg-main);
  border-top: 1px solid $border-color;

  a {
    color: $accent;
    color: var(--color-link, var(--accent, $accent));

    &:hover {
      color: $accent-hover;
      color: var(--color-link-hover, var(--accent-hover, $accent-hover));
    }
  }

  img {
    border: 6px solid $white;
    border-radius: 50%;
  }

  .timestamp {
    color: $color-light;
    font-style: italic;
  }
}
